import React from "react"
import Layout from "../components/layout"
import Seo from "../components/SEO/SEO"
import { graphql } from "gatsby"
import "../styles/politicas.scss"

export default function Politicas({ data, pageContext }) {
  const datos = data.strapiPaginasDePoliticas

  return (
    <Layout>
      <Seo
        title={datos.titulo}
        pathname={`/${pageContext.slug}/`}
        robots="noindex,follow"
      />
      <section className="container primera-seccion">
        <div className="titulo-politicas">
          <h1>{datos.titulo}</h1>
        </div>
        <div
          className="cuerpo-pol"
          dangerouslySetInnerHTML={{ __html: datos.cuerpo }}
        />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    strapiPaginasDePoliticas(slug: { eq: $slug }) {
      titulo
      cuerpo
    }
  }
`
